import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c79786f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-backdrop",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBackdropClick && _ctx.onBackdropClick(...args)))
      }, [
        _createElementVNode("div", {
          class: "modal-content",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createElementVNode("img", {
            class: "icon",
            src: _ctx.type == 'success' ? require('@/assets/icons/success.svg') : require('@/assets/icons/error.svg')
          }, null, 8, _hoisted_1),
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.type != 'success' ? _ctx.$t('something_is_wrong') : _ctx.$t('u_re_setup')), 1),
          (_ctx.message != '' && _ctx.message != null)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.message), 1))
            : _createCommentVNode("", true),
          (_ctx.primaryButtonTitle != '')
            ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                key: 1,
                class: "closeBtn",
                onClick: _ctx.action1,
                "button-name": _ctx.primaryButtonTitle,
                "button-style": "primary"
              }, null, 8, ["onClick", "button-name"]))
            : _createCommentVNode("", true),
          (_ctx.secondaryButtonTitle != '')
            ? (_openBlock(), _createBlock(_component_ButtonComponent, {
                key: 2,
                class: "closeBtn",
                onClick: _ctx.action2,
                "button-name": _ctx.secondaryButtonTitle,
                "button-style": "secondary"
              }, null, 8, ["onClick", "button-name"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}