<template>
  <div class="container">
    <div :class="setAnimation()">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>

.container {
  position: relative;
  z-index: 0;
  width: inherit;
  height: fit-content;
}

.card {
  width: 100%;
  height: min-content;
  background-color: var(--all-white);
  border-radius: 10px;
  position: relative;
}

.card-no-animation {
  width: 100%;
  height: min-content;
  background-color: var(--all-white);
  border-radius: 10px;
  position: relative;
}

.card::before,
.card::after {
  content: "";
  position: absolute;
  border-radius: 12px;
  z-index: -1;
  inset: -1.5px;
  background: conic-gradient(
      from var(--gradient-angle),
      var(--all-white),
      var(--techno-blue-5),
      var(--all-white),
      var(--techno-blue-5),
      var(--all-white)
  );
  animation: rotation 10s linear infinite;
}

</style>

<style scoped>
.card-sporty-orange-5 {
  width: 100%;
  height: min-content;
  background-color: var(--all-white);
  border-radius: 10px;
  position: relative;
}

.card-sporty-orange-5::before,
.card-sporty-orange-5::after {
  content: "";
  position: absolute;
  border-radius: 12px;
  z-index: -1;
  inset: -2.5px;
  background: conic-gradient(
      from var(--gradient-angle),
      var(--all-white),
      var(--sporty-orange-5),
      var(--all-white),
      var(--sporty-orange-5),
      var(--all-white)
  );
  animation: rotation 10s linear infinite;
}
</style>


<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'GlowingCardComponent',
  props: {
    isGlowing: {
      type: Boolean,
      required: false,
      default: true
    },
    glowColor: {
      type: String,
      required: false,
      default: 'techno-blue-5'
    }
  },
  methods: {
    setAnimation(): string {
      let style = ''
      if (this.glowColor == 'techno-blue-5') {
        style = 'card'
      } else if (this.glowColor == 'sporty-orange-5') {
        style = 'card-sporty-orange-5'
      }
      return this.isGlowing ? style : 'card-no-animation'
    }
  }
});
</script>