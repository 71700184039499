import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from "@/views/HomeView.vue";
import SignUpView from "@/views/SignUpView.vue";
import LoginView from "@/views/LoginView.vue";
import GoogleAuthCallback from "@/views/GoogleAuthCallback.vue";
import LoginDealerView from "@/views/LoginDealerView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/sign-up',
        name: 'signUp',
        component: SignUpView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/dealer-login',
        name: 'dealerLogin',
        component: LoginDealerView
    },
    {
        path: '/auth/callback',
        name: 'googleCallback',
        component: GoogleAuthCallback
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
