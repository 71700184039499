<template>
  <div class="faq-container">
    <h2>{{ $t('faq') }}</h2>
    <p class="subtitle">Lorem ipsum</p>
    <div class="questions">
      <FAQQuestionItem v-for="item in questions" :item="item" :key="item.question"/>
    </div>
  </div>
</template>

<style scoped>

.subtitle {
  margin-top: 16px;
  font-size: 20px;
  color: var(--black5);
  text-align: center;
}

.questions {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question {
  margin-top: 8px;
}

</style>

<script lang="ts">

import {defineComponent} from "vue";
import FAQQuestionItem from "@/fragments/items/FAQQuestionItem.vue";
import {FaqQuestion} from "@/types/ui/FaqQuestion";

export default defineComponent({
  name: 'FAQFragment',
  data() {
    return {
      questions: [
        new FaqQuestion('How do I create an account to post my car for sale?', 'To create an account, simply click on the "Sign Up" button located at the top right of our homepage. Fill in the required fields with your information, verify your email address, and you\'ll be ready to start posting!'),
        new FaqQuestion('What information should I include in my car sale announcement?', 'Your car sale announcement should include the following key details to attract potential buyers:\n- Make and model of the car\n- Year of manufacture\n- Mileage\n- Overall condition (any damage or issues)\n- Price\n- At least one clear photo of the vehicle'),
        new FaqQuestion('How can I edit or delete my car sale announcement?', 'Editing or deleting your car sale announcement is easy! Log into your account, go to your dashboard, and select the listing you want to change. You will see options to edit or remove your listing directly from there.'),
        new FaqQuestion('Is there a fee to post a car sale announcement on the website?', 'Posting a basic car sale announcement is completely free! However, if you want to increase visibility or add premium features to your listing, we offer several affordable options that you can choose from during the posting process.'),
        new FaqQuestion('How can I safely communicate with potential buyers?', 'Always use the messaging system provided on our website to communicate with potential buyers. This ensures that your personal contact information remains private and helps protect you from scams. For your safety, never share personal details like your home address or bank information until you have verified the buyer\'s identity and intentions.')
      ]
    }
  },
  components: {FAQQuestionItem}
})

</script>