import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createI18n} from "vue-i18n";
import EN from './locale/en.json'
import DE from './locale/de.json'
import RU from './locale/ru.json'
import Vue3TouchEvents from 'vue3-touch-events';

const locale = document.cookie.split('=')[1];
if (locale === undefined){
    document.cookie = 'locale=de'
}

const i18n = createI18n({
    locale: locale,
    legacy: false, // This ensures you are not using legacy mode
    fallbackLocale: 'de',
    messages: {
        de: DE,
        en: EN,
        ru: RU
    },
})

createApp(App)
    .use(router)
    .use(i18n)
    .use(Vue3TouchEvents)
    .mount('#app')
