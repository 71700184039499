import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1df59083"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "best-deals-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "sub-title" }
const _hoisted_4 = { class: "deals-container" }
const _hoisted_5 = { class: "deals" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_CarPostCard = _resolveComponent("CarPostCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('best_deals')), 1),
    _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t('explore_best_deals')), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ButtonComponent, {
        class: "explore-best-deals",
        "button-name": "See All 12,492 Deals",
        icon: "arrow_right_white.svg",
        "button-style": "text"
      }),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carPosts, (post) => {
          return (_openBlock(), _createBlock(_component_CarPostCard, {
            class: "deal",
            model: post,
            key: post.id
          }, null, 8, ["model"]))
        }), 128))
      ]),
      _createVNode(_component_ButtonComponent, {
        class: "load-more-btn",
        "button-name": "load_more",
        "button-style": "secondary"
      })
    ])
  ]))
}