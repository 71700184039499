<template>
  <button :disabled="!enabled" @click="!isLoading && click()" :class="$props.buttonStyle">
    <div :class="styleForContent">
      <span v-if="isLoading" class="loader"/>
      <div v-if="!isLoading" class="button-label-icon">
        <p class="button-label">{{ $t(buttonName) }}</p>
        <img :class="styleForIcon" v-if="icon != null" :src="require(`@/assets/icons/${icon}`)"/>
      </div>
    </div>
  </button>
</template>
<style scoped>
img {
  margin-left: 4px;
}

.button-label{
  font-family: "Inter", serif;
  font-weight: 600;
}
</style>

<!--Primary style-->
<style scoped>

.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-top: 2px solid #FFF;
  border-right: 2px solid transparent;
  box-sizing: border-box;
  animation: rotation-loader 1s linear infinite;
}

.button-label-icon {
  display: flex;
}

.primary-icon-disabled {
  /*
  hardcode all colors picked in
  https://angel-rs.github.io/css-color-filter-generator/
  */
  filter: brightness(0) saturate(100%) invert(59%) sepia(77%) saturate(276%) hue-rotate(188deg) brightness(107%) contrast(103%);
}

.primary {
  background-color: var(--techno-blue-5);
  border-radius: 12px;
  width: auto;
  display: flex;
  justify-content: center;
  border: none;
}

.primary:disabled, .primary[disabled] {
  background-color: var(--techno-blue-1);
}

.primary:hover:enabled {
  background-color: var(--techno-blue-4);
  border: none;
}

.primary:hover:active:enabled {
  background-color: var(--techno-blue-7);
  border: none;
}

.primary-content {
  font-size: 16px;
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  color: var(--techno-blue-1);
}

.primary-content-disabled {
  font-size: 16px;
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  color: var(--techno-blue-2);
}
</style>

<!--Primary small style-->
<style scoped>
.primary-small-icon-disabled {
  /*
  hardcode all colors picked in
  https://angel-rs.github.io/css-color-filter-generator/
  */
  filter: brightness(0) saturate(100%) invert(59%) sepia(77%) saturate(276%) hue-rotate(188deg) brightness(107%) contrast(103%);
}

.primary-small {
  background-color: var(--techno-blue-5);
  border-radius: 12px;
  width: auto;
  display: flex;
  justify-content: center;
  border: none;
}

.primary-small:disabled, .primary-small[disabled] {
  background-color: var(--techno-blue-1);
}

.primary-small:hover:enabled {
  background-color: var(--techno-blue-4);
  border: none;
}

.primary-small:hover:active:enabled {
  background-color: var(--techno-blue-7);
  border: none;
}

.primary-small-content {
  font-size: 16px;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  color: var(--techno-blue-1);
}

.primary-small-content-disabled {
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--techno-blue-2);
  padding: 16px 24px;
}
</style>

<!--Secondary style-->
<style scoped>
.secondary-icon {
  filter: brightness(0) saturate(100%) invert(19%) sepia(69%) saturate(5946%) hue-rotate(220deg) brightness(96%) contrast(98%);
}

.secondary-icon-disabled {
  /*
  hardcode all colors picked in
  https://angel-rs.github.io/css-color-filter-generator/
  */
  filter: brightness(0) saturate(100%) invert(83%) sepia(7%) saturate(0%) hue-rotate(175deg) brightness(88%) contrast(82%);
}

.secondary {
  background-color: var(--techno-blue-1);
  border-radius: 12px;
  width: auto;
  display: flex;
  justify-content: center;
  border: none;
  color: var(--techno-blue-5);
}

.secondary:disabled, .secondary[disabled] {
  background-color: var(--white2);
}

.secondary:hover:enabled {
  background-color: var(--techno-blue-1);
  border: none;
  color: var(--techno-blue-4);
}

.secondary:hover:active:enabled {
  background-color: var(--techno-blue-1);
  color: var(--techno-blue-7);
  border: none;
}

.secondary:hover:active:enabled img {
  filter: brightness(0) saturate(100%) invert(12%) sepia(58%) saturate(5682%) hue-rotate(220deg) brightness(78%) contrast(98%);
}

.secondary-content {
  font-size: 16px;
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  color: inherit;
}

.secondary-content-disabled {
  font-size: 16px;
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  color: var(--white5);
}

img {
  margin-left: 4px;
}
</style>

<!--Secondary small style-->
<style scoped>
.secondary-small-icon {
  filter: brightness(0) saturate(100%) invert(19%) sepia(69%) saturate(5946%) hue-rotate(220deg) brightness(96%) contrast(98%);
}

.secondary-small-icon-disabled {
  /*
  hardcode all colors picked in
  https://angel-rs.github.io/css-color-filter-generator/
  */
  filter: brightness(0) saturate(100%) invert(83%) sepia(7%) saturate(0%) hue-rotate(175deg) brightness(88%) contrast(82%);
}

.secondary-small {
  background-color: var(--techno-blue-1);
  border-radius: 12px;
  width: auto;
  display: flex;
  justify-content: center;
  border: none;
  color: var(--techno-blue-5);
}

.secondary-small:disabled, .secondary-small[disabled] {
  background-color: var(--white2);
}

.secondary-small:hover:enabled {
  background-color: var(--techno-blue-1);
  border: none;
  color: var(--techno-blue-4);
}

.secondary-small:hover:active:enabled {
  background-color: var(--techno-blue-1);
  color: var(--techno-blue-7);
  border: none;
}

.secondary-small:hover:active:enabled img {
  filter: brightness(0) saturate(100%) invert(12%) sepia(58%) saturate(5682%) hue-rotate(220deg) brightness(78%) contrast(98%);
}

.secondary-small-content {
  font-size: 16px;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  color: inherit;
}

.secondary-small-content-disabled {
  font-size: 16px;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  color: var(--white5);
}

img {
  margin-left: 4px;
}
</style>

<!--Outline style-->
<style scoped>
.outlined-icon {
  filter: brightness(0) saturate(100%) invert(19%) sepia(69%) saturate(5946%) hue-rotate(220deg) brightness(96%) contrast(98%);
}

.outlined-icon-disabled {
  /*
  hardcode all colors picked in
  https://angel-rs.github.io/css-color-filter-generator/
  */
  filter: brightness(0) saturate(100%) invert(83%) sepia(7%) saturate(0%) hue-rotate(175deg) brightness(88%) contrast(82%);
}

.outlined {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%230353EEFF' stroke-width='4' stroke-dasharray='14%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  width: auto;
  display: flex;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: var(--techno-blue-4);
}

.outlined:hover:enabled {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%235A92FDFF' stroke-width='4' stroke-dasharray='14%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border: none;
  color: var(--techno-blue-3);
  background-color: transparent;
}

.outlined:hover:active:enabled {
  border: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23023089FF' stroke-width='4' stroke-dasharray='14%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 12px;
  color: var(--techno-blue-7);
}

.outlined:hover:enabled img {
  filter: brightness(0) saturate(100%) invert(50%) sepia(63%) saturate(2215%) hue-rotate(201deg) brightness(104%) contrast(98%);
}

.outlined:hover:active:enabled img {
  filter: brightness(0) saturate(100%) invert(12%) sepia(58%) saturate(5682%) hue-rotate(220deg) brightness(78%) contrast(98%);
}

.outlined:disabled, .outlined[disabled] {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23E4E4E4FF' stroke-width='4' stroke-dasharray='14%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 12px;
}

.outlined-content {
  font-size: 16px;
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  color: inherit;
}

.outlined-content-disabled {
  font-size: 16px;
  width: 100%;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  color: var(--white5);
}

img {
  margin-left: 4px;
}
</style>

<!--Text style-->
<style scoped>
.text-icon {
  filter: brightness(0) saturate(100%) invert(24%) sepia(94%) saturate(7123%) hue-rotate(220deg) brightness(99%) contrast(98%);
}

.text-icon-disabled {
  /*
  hardcode all colors picked in
  https://angel-rs.github.io/css-color-filter-generator/
  */
  filter: brightness(0) saturate(100%) invert(83%) sepia(7%) saturate(0%) hue-rotate(175deg) brightness(88%) contrast(82%);
}

.text {
  background-color: transparent;
  width: auto;
  display: flex;
  justify-content: center;
  border: none;
  color: var(--techno-blue-5);
}


.text:hover:enabled {
  background-color: transparent;
  border: none;
  color: var(--techno-blue-3);
}

.text:hover:active:enabled {
  color: var(--techno-blue-7);
  border: none;
}

.text:hover:enabled img {
  filter: brightness(0) saturate(100%) invert(50%) sepia(63%) saturate(2215%) hue-rotate(201deg) brightness(104%) contrast(98%);
}

.text:hover:active:enabled img {
  filter: brightness(0) saturate(100%) invert(12%) sepia(58%) saturate(5682%) hue-rotate(220deg) brightness(78%) contrast(98%);
}


.text-content {
  font-size: 16px;
  width: 100%;
  padding: 16px 17px;
  display: flex;
  justify-content: center;
  color: inherit;
}

.text-content-disabled {
  font-size: 16px;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  color: var(--white5);
}
</style>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {BrandButtonStyle} from "@/types/ui/enums/BrandButtonStyle";

export default defineComponent({
  name: 'ButtonComponent',
  emits: ['input', 'inputError'],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    click: {
      type: Function,
      default: () => {
        console.log()
      }
    },
    buttonName: {
      required: true,
      type: String
    },
    required: Boolean,
    enabled: {
      type: Boolean,
      required: false,
      default: true
    },
    icon: String,
    buttonStyle: {
      default: BrandButtonStyle.PRIMARY,
      type: String as PropType<BrandButtonStyle>
    }
  },
  computed: {
    styleForContent(): string {
      return `${this.buttonStyle}-content${this.enabled ? '' : '-disabled'}`
    },
    styleForIcon(): string {
      return `${this.buttonStyle}-icon${this.enabled ? '' : '-disabled'}`
    }
  }
})
</script>