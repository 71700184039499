<template>
  <div class="wrapper">
    <h2 class="title">{{ $t(title) }}</h2>
    <div class="loader"></div>
  </div>
</template>

<style scoped>

.wrapper {
  margin: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  text-align: center;
}

.loader {
  margin-top: 30px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--techno-blue-5);
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}
</style>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {authorizeByGoogle} from "@/services/authService";
import {GoogleLoginDto} from "@/models/GoogleLoginDto";
import router from "@/router";

export default defineComponent({
  name: 'GoogleAuthCallback',
  setup() {

    const title = ref('')

    onMounted(() => {
      processAuthentication();
    });

    function processAuthentication() {
      const hash = window.location.hash.substring(1);
      const urlParams = new URLSearchParams(hash);
      const accessToken = urlParams.get('access_token');

      if (accessToken) {
        fetchUserProfile(accessToken);
      } else {
        title.value = 'auth_failure'
      }
    }

    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

    async function fetchUserProfile(accessToken: string) {
      try {
        const tokens = await authorizeByGoogle(new GoogleLoginDto(accessToken))
        localStorage.setItem('accessToken', `Bearer ${tokens.access_token}`)
        localStorage.setItem('refreshToken', tokens.refresh_token)
        title.value = 'auth_success'
      } catch (e) {
        title.value = 'auth_failure'
      } finally {
        await sleep(1000)
        await router.replace('/')
      }
    }

    return {
      title
    }

  }
});
</script>
