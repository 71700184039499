import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cee50d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BestDealsFragment = _resolveComponent("BestDealsFragment")!
  const _component_FAQFragment = _resolveComponent("FAQFragment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('slogan')), 1),
    _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('what_kind_of_car_are_looking_for')), 1),
    _createVNode(_component_BestDealsFragment),
    _createVNode(_component_FAQFragment)
  ]))
}