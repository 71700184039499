<template>
  <div class="custom-checkbox" @click="toggleCheck">
    <div class="checkbox">
      <img src="@/assets/icons/check-box.svg" v-show="isChecked"/>
      <img src="@/assets/icons/check-box-inactive.svg" v-show="!isChecked"/>
    </div>
    <p class="label">{{ label }}</p>
  </div>
</template>

<script lang="ts" setup>
import {ref} from 'vue';

const props = defineProps({
  modelValue: Boolean,
  label: String
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
}>();

const isChecked = ref(props.modelValue);

function toggleCheck() {
  isChecked.value = !isChecked.value;
  emit('update:modelValue', isChecked.value);
}
</script>

<style scoped>
.custom-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox{
  width: 16px;
  height: 16px;
}

.label {
  margin-left: 6px;
  font-family: 'Inter', serif;
  font-weight: 500;
  font-size: 14px;
  color: var(--techno-blue-9);
}
</style>
