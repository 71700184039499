class ApiError extends Error {
    public status: string | null

    constructor(message: string, status: string | null) {
        super(message);
        this.message = message
        this.status = status;
    }
}

export default ApiError;