<template>
  <div class="wrapper">
    <div class="login-card">
      <div class="content">
        <h3 class="title"> {{ $t('login_title') }}</h3>
        <h6 class="subtitle"> {{ $t('login_subtitle') }}</h6>
        <div class="divider-b5"></div>
        <div class="input-group">
          <InputComponent :placeholder="'example@email.com'" class="email" type="email" :required="true"
                          @input="listenEmail"/>
          <InputComponent :placeholder="'password'" class="password" type="password" :required="true"
                          @input="listenPassword"/>
          <div class="controls">
            <CheckboxComponent v-model="remember" :label="$t('remember_me')"/>
            <ButtonComponent :click="forgotBtn" class="forgot-btn" button-name="forgot_password" button-style="text"/>
          </div>
        </div>
        <ButtonComponent :click="login" :button-name="'login'" class="login-btn" icon="arrow_right_white.svg"
                         :enabled="formValid" :is-loading="isLoading"/>
        <div class="or">
          <div class="divider-w5"></div>
          <p class="or-label">{{ $t('or') }}</p>
          <div class="divider-w5"></div>
        </div>
        <GoogleAuthButton/>
        <p class="sign-up-btn">
          {{ $t('dont_have_account') }}
          <router-link to="/sign-up" class="sign-up-link">
            {{ $t('sign_up') }}
          </router-link>
        </p>
      </div>
    </div>
    <img class="illustration unselectable" src="@/assets/images/banner1.jpg">
  </div>
  <ModalComponent
      v-model="showErrorModal"
      :message="errorMessage"
      :secondary-button-title="$t('go_back')"
      :type="'error'"
  />
  <ModalComponent
      v-model="successModal"
      :primary-button-title="$t('main_page')"
      :primary-button-action="goToMain"
      :secondary-button-title="$t('my_profile')"
      :secondary-button-action="goToProfile"
      :backdrop-click-action="goToMain"
      :type="'success'"
  />
</template>

<style scoped>

.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 48px 80px 80px;
}

.illustration {
  border-radius: 16px;
  max-height: 588px;
  object-fit: contain;
}

.login-card {
  padding: 45px 0;
  min-width: 416px;
  margin-right: 100px;
}

.title {
  text-align: start;
}

.content {
  flex-direction: column;
  display: flex;
}

.title {
  font-size: 32px;
}

.subtitle {
  margin-top: 12px;
}

.divider-b5 {
  margin-top: 24px;
}

.input-group {
  margin-top: 24px;
  width: 100%;
}

.password {
  margin-top: 12px;
}

.login-btn {
  margin-top: 20px;
  width: 100%;
}

.controls {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.forgot-btn * {
  padding: 0;
}

.or {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 24px;
}

.or-label {
  color: var(--white5);
  font-size: 14px;
  margin: 0 16px;
}

.sign-up-btn {
  color: var(--black5);
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
}

.sign-up-link {
  cursor: pointer;
  font-family: 'Inter', serif;
  font-weight: 600;
  color: var(--techno-blue-5);
}

</style>

<script lang="ts">

import {computed, defineComponent, onMounted, ref, watch} from "vue";
import InputComponent from "@/components/InputComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import {useI18n} from "vue-i18n";
import {LoginModelDto} from "@/models/LoginModelDto";
import {getTokens} from "@/services/authService";
import GoogleAuthButton from "@/components/GoogleAuthButton.vue";
import router from "@/router";
import ModalComponent from "@/components/ModalComponent.vue";
import CheckboxComponent from "@/components/CheckboxComponent.vue";

export default defineComponent({
  name: 'LoginView',
  components: {CheckboxComponent, ModalComponent, GoogleAuthButton, ButtonComponent, InputComponent},
  setup() {

    const {t, locale} = useI18n();

    const updateTitle = () => {
      document.title = t('login');
    };

    watch(locale, () => {
      updateTitle();
    });

    onMounted(updateTitle);


    const email = ref('')
    const password = ref('')
    const remember = ref(false)

    const isLoading = ref(false)

    const showErrorModal = ref(false)
    const errorMessage = ref('')

    const successModal = ref(false)

    const goToProfile = () => {
      router.replace('/profile')
    }

    const goToMain = () => {
      router.replace('/')
    }

    const forgotBtn = () => {
      console.log('click')
    }

    const login = () => {
      isLoading.value = true
      const loginModel = new LoginModelDto(email.value, password.value)
      loginRequest(loginModel, remember.value)
    }

    async function loginRequest(loginModel: LoginModelDto, remeberMe: boolean) {
      isLoading.value = true;
      try {
        const tokens = await getTokens(loginModel);
        if (remeberMe) {
          localStorage.setItem('accessToken', `Bearer ${tokens.access_token}`)
          localStorage.setItem('refreshToken', tokens.refresh_token)
        } else {
          sessionStorage.setItem('accessToken', `Bearer ${tokens.access_token}`)
          sessionStorage.setItem('refreshToken', tokens.refresh_token)
        }
        successModal.value = true
      } catch (e: any) {
        errorMessage.value = e.message
        showErrorModal.value = true
      } finally {
        isLoading.value = false;
      }
    }

    const listenEmail = (n: string) => {
      email.value = n
    }

    const listenPassword = (n: string) => {
      password.value = n
    }

    const formValid = computed(() => {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value) &&
          /.{8,}/.test(password.value)
    })

    const goMainPage = () => {
      router.replace('/')
    }

    return {
      formValid,
      listenEmail,
      listenPassword,
      password,
      login,
      isLoading,
      remember,
      showErrorModal,
      errorMessage,
      forgotBtn,
      goMainPage,
      goToProfile,
      goToMain,
      successModal
    }
  },
})

</script>
