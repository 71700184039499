import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29bdb603"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-component" }
const _hoisted_2 = { class: "input-holder" }
const _hoisted_3 = ["placeholder", "type"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "input-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlowingCardComponent = _resolveComponent("GlowingCardComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.error == null ? 'input-wrapper' : 'input-wrapper-error')
    }, [
      _createVNode(_component_GlowingCardComponent, { "is-glowing": _ctx.isFocused }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input) = $event)),
              onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
              onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
              id: "primary-input",
              placeholder: _ctx.$t(_ctx.placeholder),
              type: _ctx.inputType
            }, null, 40, _hoisted_3), [
              [_vModelDynamic, _ctx.input]
            ]),
            (_ctx.type == 'password')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.passwordVisible ? require('@/assets/icons/eye_cross.svg') : require('@/assets/icons/eye.svg'),
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.togglePasswordVisibility && _ctx.togglePasswordVisibility(...args)))
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["is-glowing"])
    ], 2),
    (_ctx.error != null)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}