<template>
  <div class="footer">
    <div class="content">
      <div class="logo">
        <img src="@/assets/icons/logo_white.svg" alt="Logo">
        <p class="slogan">{{ $t('slogan') }}</p>
      </div>
      <div class="table">
        <div class="footer_column" v-for="item in footerItems" :key="item.titleLocaleCode">
          <p class="ul_title">{{ $t(item.titleLocaleCode) }}</p>
          <ul class="footer_ul">
            <li v-for="li in item.items" :key="li.titleLocaleCode">
              <router-link class="router-link" :to="li.url">{{ $t(li.titleLocaleCode) }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer_column language-section">
          <p class="ul_title">{{ $t('language') }}</p>
          <LanguageSelectorComponent class="language-select" :selected="currentLocale" @selectedLocale="changeLocale"/>
        </div>
      </div>
    </div>
    <p class="caroserie">Caroserie GmbH • 2024</p>
    <img class="glow-bottom unselectable" src="@/assets/effects/glow.png"/>
    <img class="glow-top unselectable" src="@/assets/effects/glow.png"/>
  </div>
</template>

<style scoped>
.footer {
  background-color: var(--techno-blue-9);
  padding: 80px 80px 40px;
  position: relative;
  overflow: hidden;
}

.glow-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(180deg);
}

.glow-top {
  position: absolute;
  top: 0;
  right: 0;
}

.content {
  display: flex;
  justify-content: space-between;
}

.caroserie {
  margin-top: 80px;
  text-align: center;
}

.slogan {
  margin-top: 16px;
}

p {
  font-size: 16px;
  color: var(--white5);
}

li {
  font-size: 16px;
  margin-top: 16px;
  color: var(--white5);
  cursor: pointer;
}

.language-select {
  z-index: 15;
}

.table {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.footer_column {
  width: 200px;
  margin-left: 12px;
}

.footer_ul {
  list-style-type: none;
}

.ul_title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: var(--white1);
}

.selector {
  margin-top: 16px;
}

</style>

<script lang="ts">

import {defineComponent, ref} from "vue";
import LanguageSelectorComponent from "@/components/LanguageSelectorComponent.vue";

export default defineComponent({
  name: 'FooterComponent',
  components: {LanguageSelectorComponent},
  data() {
    return {
      footerItems: {
        navigation: {
          titleLocaleCode: 'navigation',
          items: [
            {
              titleLocaleCode: 'main_page',
              url: '/'
            },
            {
              titleLocaleCode: 'search',
              url: '/'
            }
          ]
        },
        legal: {
          titleLocaleCode: 'legal',
          items: [
            {
              titleLocaleCode: 'imprint',
              url: '/'
            },
            {
              titleLocaleCode: 'general_terms_conditions',
              url: '/'
            },
            {
              titleLocaleCode: 'privacy_policy',
              url: '/'
            }
          ]
        },
        company: {
          titleLocaleCode: 'company',
          items: [
            {
              titleLocaleCode: 'about',
              url: '/'
            },
            {
              titleLocaleCode: 'contact',
              url: '/'
            },
            {
              titleLocaleCode: 'help',
              url: '/'
            }
          ]
        }
      }
    }
  },
  setup() {
    const changeLocale = (localeCode: string) => {
      document.cookie = `locale=${localeCode}`
      document.location.reload()
    }

    let locale = document.cookie.split('=')[1];
    if (locale == undefined) {
      locale = 'de'
    }

    const currentLocale = ref(locale)

    return {
      changeLocale,
      currentLocale,
    };
  }
})

</script>