<template>
  <div class="question" @click="toggleQuestion">
    <div class="question-title">
      <h3>{{ item.question }}</h3>
      <img
          :src="isExposed ? require('@/assets/icons/chevron-up-dark.svg') : require('@/assets/icons/chevron-down-dark.svg')"
          :alt="isExposed ? 'Up' : 'Down'"/>
    </div>
    <transition name="slide" v-if="!isSafari">
      <div class="answer" v-show="isExposed">
        <div class="divider-b5"/>
        <p>{{ item.answer }}</p>
      </div>
    </transition>
    <div class="answer" v-if="isSafari" v-show="isExposed">
      <div class="divider-b5"/>
      <p>{{ item.answer }}</p>
    </div>
  </div>
</template>

<style scoped>
.question-title {
  display: flex;
  justify-content: space-between;
}

.question {
  width: 100%;
  max-width: 848px;
  padding: 24px;
  border-radius: 24px;
  background-color: var(--bg-blue);
}

.divider-b5 {
  margin: 24px 0;
}

.slide-enter-active, .slide-leave-active {
  transition: max-height 0.5s linear;
  overflow: hidden;
}

.slide-enter-from, .slide-leave-to {
  max-height: 0;
}

.slide-enter-to, .slide-leave-from {
  max-height: 250px;
}

</style>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from "vue";
import { FaqQuestion } from "@/types/ui/FaqQuestion";

export default defineComponent({
  name: 'FAQQuestionItem',
  props: {
    item: {
      type: Object as PropType<FaqQuestion>,
      required: true
    }
  },
  setup() {
    const isExposed = ref(false);
    const toggleQuestion = () => {
      isExposed.value = !isExposed.value;
    };

    const isSafari = computed(() => {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    });

    return {
      isExposed, toggleQuestion, isSafari
    };
  }
})
</script>
