<template>
  <div class="home-container">
    <h1 class="title">{{ $t('slogan') }}</h1>
    <h3 class="subtitle">{{ $t('what_kind_of_car_are_looking_for') }}</h3>
    <BestDealsFragment/>
    <FAQFragment/>
  </div>
</template>

<style scoped>

.home-container {
  padding: 100px 0 200px;
}

.subtitle {
  color: var(--white5);
  margin-top: 32px;
}

</style>

<script lang="ts">

import {defineComponent, onMounted, watch} from "vue";
import {useI18n} from "vue-i18n";
import ButtonComponent from "@/components/ButtonComponent.vue";
import BestDealsFragment from "@/fragments/BestDealsFragment.vue";
import FAQFragment from "@/fragments/FAQFragment.vue";

export default defineComponent({
  name: 'HomeView',
  components: {FAQFragment, BestDealsFragment},
  setup() {
    const {t, locale} = useI18n();

    const updateTitle = () => {
      document.title = t('slogan');
    };

    watch(locale, () => {
      updateTitle();
    });

    onMounted(updateTitle);
  }
})

</script>