<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: 'GoogleAuthButton',
  setup() {
    const googleSignUp = () => {
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
      const redirect_uri = `${window.location.origin}/auth/callback`;
      const response_type = 'token';
      const scope = encodeURIComponent('email profile openid');
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&prompt=consent`;
      window.location.href = authUrl;
    }

    return {
      googleSignUp
    }
  }
})
</script>

<template>
  <button class="google-auth" @click="googleSignUp">
    <img class="google-icon" src="@/assets/icons/google_logo.svg">
    <p class="google-label">{{ $t('sign_with_google') }}</p>
  </button>
</template>

<style scoped>

.google-auth {
  background-color: var(--all-white);
  outline: none;
  border: 2px solid var(--techno-blue-5);
  border-radius: var(--standard-round);
  margin-top: 24px;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  color: var(--techno-blue-5);
  padding: 20px;
  display: flex;
  align-items: center;
}

.google-label {
  font-family: 'Inter', serif;
  font-weight: 600;
}

.google-icon {
  margin-right: 8px;
}
</style>