<template>
  <div class="best-deals-container">
    <h2 class="title">{{ $t('best_deals') }}</h2>
    <h4 class="sub-title">{{ $t('explore_best_deals') }}</h4>
    <div class="deals-container">
      <ButtonComponent class="explore-best-deals" button-name="See All 12,492 Deals" icon="arrow_right_white.svg"
                       button-style="text"/>

      <div class="deals">
        <CarPostCard class="deal" v-for="post in carPosts" :model="post" :key="post.id"/>
      </div>
      <ButtonComponent class="load-more-btn" button-name="load_more" button-style="secondary"/>
    </div>
  </div>
</template>

<style scoped>
.best-deals-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  position: relative;
  vertical-align: middle;
}

.title:after {
  vertical-align: middle;
  content: url("@/assets/icons/crown.svg");
  margin-left: 8px;
}

.sub-title {
  color: var(--white5);
}

.explore-best-deals {
  width: fit-content;
}

.deals-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
}

.deals {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 308px); /* Fixed width for each column */
  grid-gap: 16px;
  justify-content: center;
  width: fit-content;
}

.load-more-btn {
  margin-top: 16px;
  width: 100%;
}

</style>

<script lang="ts">

import {defineComponent} from "vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import {CarPostModel} from "@/models/CarPostModel";
import CarPostCard from "@/fragments/items/CarPostCard.vue";

export default defineComponent({
  name: 'BestDealsFragment',
  components: {CarPostCard, ButtonComponent},
  setup() {

    const carPosts =
        [
          new CarPostModel(
              1,
              true,
              true,
              true,
              'Neu-Ulm, DE',
              "2024-05-04",
              "123",
              "25000",
              "USD",
              "Toyota Corolla",
              "15000",
              'km',
              "2019",
              [
                "https://prod.pictures.autoscout24.net/listing-images/57b40860-b23b-4a55-9c0d-dc8bcb187199_b63c37fc-59b1-405b-964a-861f6dbddca8.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/57b40860-b23b-4a55-9c0d-dc8bcb187199_28e42197-eaf5-410e-be9d-d015e38dc2d2.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/57b40860-b23b-4a55-9c0d-dc8bcb187199_f45b21f2-e8c9-46f3-b267-3f9de31a8441.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/57b40860-b23b-4a55-9c0d-dc8bcb187199_fa61958c-413b-45ae-9eb2-af61cd968145.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/57b40860-b23b-4a55-9c0d-dc8bcb187199_713db955-54ea-4874-80ce-c290eac9d1fc.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/57b40860-b23b-4a55-9c0d-dc8bcb187199_b54b5b11-883b-4374-969c-d881327444fc.jpg/720x540.webp"
              ]
          ),
          new CarPostModel(
              2,
              true,
              true,
              true,
              "Munich, DE",
              "2024-04-20",
              "456",
              "22000",
              "EUR",
              "BMW 320i",
              "30000",
              "km",
              "2018",
              [
                "https://prod.pictures.autoscout24.net/listing-images/d6e4b7ad-4400-40cf-a5fb-b42e5344f12d_79f3345a-28c7-4b56-912a-e220daa33d03.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/d6e4b7ad-4400-40cf-a5fb-b42e5344f12d_2a5dc677-46ef-4444-a9fc-50ce284b0607.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/d6e4b7ad-4400-40cf-a5fb-b42e5344f12d_e8f3c111-04b8-46ea-a18a-2a053dfaea78.jpg/720x540.webp",
              ]
          ),
          new CarPostModel(
              3,
              true,
              false,
              true,
              "Berlin, DE",
              "2024-05-01",
              "789",
              "18000",
              "EUR",
              "Volkswagen Golf",
              "25000",
              "km",
              "2017",
              [
                "https://prod.pictures.autoscout24.net/listing-images/5c419f07-cead-427a-bb47-058de458b7d7_a9b3c330-c6b6-429f-9c6a-e246f98ea481.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/5c419f07-cead-427a-bb47-058de458b7d7_54b7a1c7-3b4f-4e29-84ef-22600513e4da.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/5c419f07-cead-427a-bb47-058de458b7d7_64fd1617-9d9c-4a1f-9589-e31d4f1b1594.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/5c419f07-cead-427a-bb47-058de458b7d7_930cf355-7c60-456d-9e41-1c79e0b5a095.jpg/720x540.webp",
              ]
          ),
          new CarPostModel(
              4,
              true,
              false,
              true,
              "Stuttgart, DE",
              "2024-05-03",
              "230",
              "35000",
              "EUR",
              "Mercedes-Benz C-Class",
              "10000",
              "km",
              "2020",
              [
                "https://prod.pictures.autoscout24.net/listing-images/a71abbc2-fa12-49a6-8870-b78268baeb7d_62381518-8afb-409e-b700-16cf7a74fb13.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/a71abbc2-fa12-49a6-8870-b78268baeb7d_9f5e3398-4cb1-4b47-8c9f-6d8fbe2f23e7.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/a71abbc2-fa12-49a6-8870-b78268baeb7d_9ccc4f62-02e7-45d0-aae8-736ed94556db.jpg/720x540.webp",
              ]
          ),
          new CarPostModel(
              5,
              true,
              true,
              false,
              "Hamburg, DE",
              "2024-04-15",
              "510",
              "18000",
              "EUR",
              "Ford Focus",
              "45000",
              "km",
              "2016",
              [
                "https://prod.pictures.autoscout24.net/listing-images/24e2495f-91e1-4000-8075-61bb7095925d_bc07a1cc-0134-4d79-b6c3-8bb1f4fe7855.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/24e2495f-91e1-4000-8075-61bb7095925d_335b6a53-5378-4214-96b0-bbb64885c61d.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/24e2495f-91e1-4000-8075-61bb7095925d_81757dec-aa53-46b0-aaa8-a28037913673.jpg/720x540.webp",
              ]
          ),
          new CarPostModel(
              6,
              true,
              false,
              true,
              "Dresden, DE",
              "2024-05-02",
              "322",
              "27000",
              "EUR",
              "Audi A4",
              "20000",
              "km",
              "2018",
              [
                "https://prod.pictures.autoscout24.net/listing-images/1565f81f-07af-4d57-b19c-85e444522f87_b6af0ee9-8462-4c04-8cd8-e5e737849dac.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/1565f81f-07af-4d57-b19c-85e444522f87_369d4404-0fa2-4b1f-8c3b-00de0782e9ee.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/1565f81f-07af-4d57-b19c-85e444522f87_1e937253-3fcb-4023-a51a-89f57a09e71d.jpg/720x540.webp",
              ]
          ),
          new CarPostModel(
              7,
              true,
              true,
              false,
              "Leipzig, DE",
              "2024-04-28",
              "289",
              "30000",
              "EUR",
              "Porsche 911",
              "25000",
              "km",
              "2015",
              [
                "https://prod.pictures.autoscout24.net/listing-images/eef52847-b6c0-45a9-b9b7-1294cf8e3c10_0bae0348-92c1-4028-b5c9-05be08a7fed5.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/eef52847-b6c0-45a9-b9b7-1294cf8e3c10_9644b564-928e-4a74-8cf6-0691d6a6e220.jpg/720x540.webp",
                "https://prod.pictures.autoscout24.net/listing-images/eef52847-b6c0-45a9-b9b7-1294cf8e3c10_e710c36c-0854-455a-8393-06d3003be302.jpg/720x540.webp",
              ]
          ),
          new CarPostModel(
              8,
              true,
              false,
              false,
              "Nuremberg, DE",
              "2024-05-05",
              "123",
              "15000",
              "EUR",
              "Volkswagen Polo",
              "60000",
              "km",
              "2013",
              [
                "https://prod.pictures.autoscout24.net/listing-images/d263aca7-1f24-40f9-ae62-9cf9a1b2653f_cecf471c-cdc0-49f7-9f53-75d9e1679531.jpg/720x540.webp",
                "",
              ]
          ),
        ];

    return {
      carPosts
    }
  }
})

</script>
