<template>
  <div class="nav-bar">
    <router-link to="/" class="navbar-brand">
      <img src="../assets/icons/logo_blue.svg" alt="Logo">
    </router-link>
    <div class="nav-items">
      <ul>
        <li class="nav-item" v-for="nav in navItems" :key="nav.name">
          <router-link class="router-link" :to="nav.url">{{ $t(nav.localeCode) }}</router-link>
        </li>
      </ul>
      <ButtonComponent button-name="sign_up" @click="navigate('/sign-up')" button-style="primary-small"/>
      <ButtonComponent class="login" button-name="login" @click="navigate('/login')" button-style="secondary-small"/>
    </div>
  </div>
</template>

<style scoped>

.nav-bar {
  padding: 0 80px;
  height: 52px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.nav-items {
  height: inherit;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

ul {
  height: inherit;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

li {
  display: inline;
  color: var(--black1);
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 16px;
  padding: 16px;
  margin: 0 16px;
  cursor: pointer;
}

.login {
  margin-left: 8px;
}

</style>
<script lang="ts">
import {defineComponent} from "vue";
import router from "@/router";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default defineComponent({
  name: 'NavBarComponent',
  components: {ButtonComponent},
  methods: {
    navigate(url: string) {
      router.push(url)
    }
  },
  data() {
    return {
      navItems: [
        {
          name: 'main',
          localeCode: 'home',
          url: '/'
        },
        {
          name: 'search',
          localeCode: 'search',
          url: '/'
        },
      ]
    }
  }
})
</script>