import axios from 'axios';
import ApiError from "@/exceptions/ApiError";

const apiService = axios.create({
    baseURL: 'https://caroserieapi.com/api',
    timeout: 10000,
});

apiService.interceptors.request.use(request => {
    request.headers['Accept-Language'] = document.cookie.split('=')[1];
    return request;
});

apiService.interceptors.response.use(response => {
    console.log('Response:', response);
    return response;
}, error => {
    if (error.response && error.response.data) {
        const {status, message} = error.response.data;
        console.log(message)
        throw new ApiError(message, status)
    } else {
        throw new ApiError('Unknown error', null)
    }
});

export default apiService;
