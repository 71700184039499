<template>
  <GlowingCardComponent class="car-item" :is-glowing="model.isPromoted" glow-color="sporty-orange-5">
    <div class="card-container">
      <div class="media">
        <img v-if="!isLoaded" class="placeholder unselectable" src="@/assets/icons/image_placeholder.svg">
        <img class="banner unselectable" alt="banner" :src="model.banners[currentBanner]"
             :key="currentBanner" @load="imageLoaded" @error="imageLoadError"
             :style="{display: isLoaded ? 'block' : 'none'}"/>
        <div class="indicators">
          <div :class="i == currentBanner + 1  ? 'indicator-active' : 'indicator'" v-for="i in model.banners.length"
               :key="i" @mouseover="mouseHover(i)"/>
        </div>
        <div class="location">{{ model.location }}</div>
        <div class="badges">
          <img src="@/assets/icons/promoted.svg" class="badge unselectable" v-if="model.isPromoted"/>
          <img src="@/assets/icons/eco.svg" class="badge unselectable" v-if="model.isEco"/>
          <img src="@/assets/icons/verified.svg" class="badge unselectable" v-if="model.isVerified"/>
        </div>
      </div>
      <div class="data">
        <div class="header">
          <p class="post-date">{{ model.postDate }}</p>
          <p class="view-count">{{ model.viewCount }}</p>
        </div>
        <div class="divider-b5"/>
        <div class="titles">
          <p class="price">{{ `${model.price}  ${model.currency}` }}</p>
          <p class="brand-model">{{ model.brandModel }}</p>
        </div>
        <div class="divider-b5"/>
        <div class="characteristics">
          <p class="mileage">{{ model.mileage + model.mileageUnit }}</p>
          <p class="year">{{ model.year }}</p>
          <div></div>
        </div>
      </div>
    </div>
  </GlowingCardComponent>
</template>

<style scoped>

.divider-b5 {
  margin-top: 12px;
}

.car-item {
  width: 308px;
  border-radius: var(--standard-round);
  background-color: var(--techno-blue-7);
}

.card-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.media {
  width: 100%;
  height: 200px;
  position: relative;
  background-color: var(--white3);
}

.placeholder {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.indicators {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 12px;
  right: 12px;
  top: 0;
  height: 100%;
  align-items: flex-end;
}

.indicator-active, .indicator {
  height: 100%;
  border-radius: 2px;
  width: 100%;
  margin: 0 4px;
  position: relative;
}

.indicator::before, .indicator::after {
  content: "";
  background-color: var(--white1);
  opacity: 0.2;
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 16px;
  left: 0;
}

.indicator-active::before, .indicator-active::after {
  content: "";
  background-color: var(--white1);
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 16px;
  left: 0;
}

.banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.badges {
  position: absolute;
  top: 16px;
  left: 16px;
}

.badge {
  margin-right: 4px;
}

.location {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  background-color: rgba(17, 17, 17, 0.7);
  font-size: 12px;
  border-radius: 8px;
  color: var(--all-white);
  font-family: 'Inter', serif;
  font-weight: 500;
}

.location:before {
  vertical-align: middle;
  content: url("@/assets/icons/map_pin.svg");
  margin-right: 4px;
}

.data {
  padding: 16px;
  background-color: var(--white1);
}

.header {
  display: flex;
  justify-content: space-between;
}

.post-date, .view-count {
  color: var(--black5);
  font-size: 14px;
  font-family: 'Inter', serif;
  font-weight: 500;}

.post-date:before {
  vertical-align: middle;
  content: url("@/assets/icons/calendar.svg");
  margin-right: 4px;
}

.view-count:before {
  vertical-align: middle;
  content: url("@/assets/icons/view.svg");
  margin-right: 4px;
}

.titles {
  margin-top: 12px;
}

.price {
  font-size: 24px;
  color: var(--black1);
  font-family: 'Inter', serif;
  font-weight: 500;}

.brand-model {
  margin-top: 12px;
  font-size: 20px;
  color: var(--black1);
  font-family: 'Inter', serif;
  font-weight: 500;
}

.characteristics {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.mileage, .year {
  font-family: 'Inter', serif;
  font-weight: 500;
  color: var(--black3);
  font-size: 16px;
}

.mileage:before {
  vertical-align: middle;
  content: url("@/assets/icons/mileage.svg");
  margin-right: 4px;
}

.year:before {
  vertical-align: middle;
  content: url("@/assets/icons/car-checkmark.svg");
  margin-right: 4px;
}

</style>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";
import GlowingCardComponent from "@/components/GlowingCardComponent.vue";
import {CarPostModel} from "@/models/CarPostModel";

export default defineComponent({
  name: 'CarPostCard',
  props: {
    model: {
      required: true,
      type: Object as PropType<CarPostModel>
    }
  },
  methods: {
    mouseHover(position: number) {
      this.currentBanner = position - 1
    }
  },
  components: {GlowingCardComponent},
  setup() {
    const isLoaded = ref(false);
    const currentBanner = ref(0)

    const imageLoaded = () => {
      isLoaded.value = true;
    };
    const imageLoadError = () => {
      isLoaded.value = false;
    };
    return {
      currentBanner, imageLoaded, isLoaded, imageLoadError
    }
  }
})
</script>
