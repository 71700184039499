<template>
  <div class="wrapper">
    <div class="sign-up-card">
      <div class="content">
        <h3 class="title"> {{ $t('sign_up_title') }}</h3>
        <h6 class="subtitle"> {{ $t('sign_up_subtitle') }}</h6>
        <ButtonComponent class="dealer-btn" button-name="sign_up_as_dealer" button-style="text"
                         icon="arrow_right_white.svg" @click="router().push('/dealer-login')"/>
        <div class="divider-b5"/>
        <div class="input-group">
          <InputComponent :placeholder="'your_name'" class="name" :required="true" @input="listenUsername"/>
          <InputComponent :placeholder="'example@email.com'" class="email" type="email" :required="true"
                          @input="listenEmail"/>
          <InputComponent :placeholder="'create_password'" class="password" type="password" :required="true"
                          :error="error" :on-input-focus="onPasswordFocus"
                          @input="listenPassword"/>
        </div>
        <div class="password-regulations" v-if="password != ''">
          <p :class="regulation.regEx.test(password) ? 'rule-label-success' : 'rule-label-error'"
             v-for="regulation in passwordRegulations" :key="regulation.label"
             :style="{color: regulation.regEx.test(password) ? 'var(--success-6)' : 'var(--error-5)'}">
            {{ regulation.label }}</p>
        </div>
        <ButtonComponent :click="signUpAction" :button-name="'sign_up'" class="sign-up-btn" icon="arrow_right_white.svg"
                         :enabled="formValid" :is-loading="isLoading"/>
        <p class="agreement-label">{{ $t('agree_by_signing_up') }}</p>
        <div class="or">
          <div class="divider-w5"></div>
          <p class="or-label">{{ $t('or') }}</p>
          <div class="divider-w5"></div>
        </div>
        <GoogleAuthButton/>
        <p class="sign-in-btn">
          {{ $t('already_have_account') }}
          <router-link to="/login" class="login-btn">
            {{ $t('login') }}
          </router-link>
        </p>
      </div>
    </div>
    <div class="illustration"></div>
  </div>
</template>

<style scoped>

.wrapper {
  display: flex;
  padding: 48px 0 120px;
}

.illustration {
  margin-left: 80px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 100%;
  background-color: var(--techno-blue-5);
}

.sign-up-card {
  min-width: 416px;
  max-width: 416px;
  margin-left: 80px;
}

.title {
  text-align: start;
}

.content {
  flex-direction: column;
  display: flex;
}

.title {
  font-size: 32px;
}

.subtitle {
  text-align: start;
  margin-top: 12px;
}

.dealer-btn {
  margin-top: 16px;
  width: fit-content;
}

.dealer-btn * {
  padding: 0;
}

.divider-b5 {
  margin: 24px 0 24px;
}

.input-group {
  width: 100%;
}

.email {
  margin-top: 12px;
}

.password {
  margin-top: 12px;
}

.agreement-label {
  font-size: 14px;
  color: var(--white5);
  text-align: center;
  margin-top: 16px;
}

.sign-up-btn {
  margin-top: 20px;
  width: 100%;
}

.login-btn {
  cursor: pointer;
  color: var(--techno-blue-5);
  font-family: 'Inter', serif;
  font-weight: 600;
}

.sign-in-btn {
  color: var(--black5);
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
}

.password-regulations {
  width: 100%;
  margin-top: 12px;
  display: flex;
}

.rule-label-success {
  font-size: 14px;
  margin-left: 4px;
  color: var(--success-6);
  padding: 9px 12px;
  background-color: var(--success-1);
  border-radius: 8px;
}

.rule-label-error {
  border-radius: 8px;
  font-size: 14px;
  margin-left: 4px;
  color: var(--error-5);
  padding: 9px 12px;
  background-color: var(--error-1);
}

.or {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 24px;
}

.or-label {
  color: var(--white5);
  font-size: 14px;
  margin: 0 16px;
}

</style>

<script lang="ts">

import {computed, defineComponent, onMounted, ref, watch} from "vue";
import InputComponent from "@/components/InputComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import {useI18n} from "vue-i18n";
import GoogleAuthButton from "@/components/GoogleAuthButton.vue";
import router from "@/router";
import {signUp} from "@/services/authService";
import {SignUpDto} from "@/models/SignUpDto";

export default defineComponent({
  name: 'SignUpView',
  methods: {
    router() {
      return router
    }
  },
  components: {GoogleAuthButton, ButtonComponent, InputComponent},
  data() {
    return {
      passwordRegulations: [
        {
          label: '8+ characters',
          regEx: /.{8,}/
        },
        {
          label: 'Special symbols',
          regEx: /.*[!@#$%^&*()\-_+=].*/
        },
        {
          label: 'At least 1 capital letter',
          regEx: /.*[A-Z].*/
        }
      ]
    }
  },
  setup() {

    const {t, locale} = useI18n();

    const updateTitle = () => {
      document.title = t('sign_up');
    };

    watch(locale, () => {
      updateTitle();
    });

    onMounted(updateTitle);

    const username = ref('')
    const email = ref('')
    const password = ref('')

    const error = ref<string | null>(null)
    const isLoading = ref(false)

    const signUpAction = async () => {
      isLoading.value = true
      try {
        const signUpResponse = await signUp(new SignUpDto(email.value, username.value, password.value))
        if (signUpResponse.success) {
          await router.push('/login')
        } else {
          //todo error notify
        }
      } catch (e) {
        console.error(e)
      } finally {
        isLoading.value = false
      }
    }

    const listenUsername = (n: string) => {
      username.value = n
    }

    const listenEmail = (n: string) => {
      email.value = n
    }

    const listenPassword = (n: string) => {
      password.value = n
    }

    const onPasswordFocus = () => {
      error.value = null
    }

    const formValid = computed(() => {
      return /\b[a-zA-Z]{3,}\b/.test(username.value) &&
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value) &&
          /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*()-_+=])[A-Za-z0-9!@#$%^&*()-_+=]{8,}$/.test(password.value)
    })

    return {
      formValid,
      username,
      listenUsername,
      listenEmail,
      listenPassword,
      password,
      signUpAction,
      error,
      onPasswordFocus,
      isLoading,
    }
  },
})

</script>
