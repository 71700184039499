<template>
  <div class="selector" @click="toggleDropdown" ref="dropdownParent">
    <div class="selected">
      <img :src="require(`@/assets/icons/${current.icon}`)" :alt="current.title">
      <p class="selected_language">{{ current.title }}</p>
      <img :src="isOpen ? require('@/assets/icons/chevron_up.svg') : require('@/assets/icons/chevron_down.svg')"
           :alt="isOpen ? 'Up' : 'Down'"/>
    </div>
    <transition name="dropdown">
      <div v-if="isOpen" class="options">
        <div class="option" v-for="lan in languages" :key="lan.code" @click="selectLanguage(lan)">
          <img :src="require(`@/assets/icons/${lan.icon}`)" :alt="lan.title"/>
          <p class="option-language">{{ lan.title }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>

.selector {
  position: relative;
  width: 200px;
  background-color: rgba(245, 245, 245, 0.1);
  border-radius: var(--standard-round);
  padding: 16px 18px;
  cursor: default;
}

.selected {
  display: flex;
  align-items: center;
}

.selected_language {
  width: 100%;
  margin-left: 8px;
  color: var(--white1);
  font-weight: 600;
}

.option-language {
  width: 100%;
  margin-left: 8px;
  color: var(--white1);
}

.option {
  display: flex;
  margin-top: 12px;
}

.options {
  width: 200px;
  position: absolute;
  overflow-y: auto;
  left: 0;
  top: 60px;
  border-radius: var(--standard-round);
  z-index: 999;
  background-color: rgba(245, 245, 245, 0.1);
  padding: 0 18px 12px;
}

</style>

<script lang="ts">
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  emits: ['selectedLocale'],
  name: 'LanguageSelectorComponent',
  props: {
    selected: {
      required: true,
      type: String
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.addEventListener('click', this.closeDropdownOnClickOutside);
      } else {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
      }
    },
    closeDropdownOnClickOutside(event: MouseEvent) {
      if (!(this.$refs.dropdownParent as HTMLElement).contains(event.target as Node)) {
        this.isOpen = false;
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
      }
    },
    selectLanguage(language: { code: string, title: string, icon: string }) {
      this.current = language;
      this.isOpen = false;
      document.removeEventListener('click', this.closeDropdownOnClickOutside);
    }
  },
  setup(props, {emit}) {
    const languages = [
      {
        code: 'en',
        title: "English",
        icon: 'en_icon.svg'
      },
      {
        code: 'de',
        title: "Deutsch",
        icon: 'de_icon.svg'
      },
      {
        code: 'ru',
        title: "Russian",
        icon: 'ru_icon.svg'
      },
    ]
    const current = ref(languages.find(lan => lan.code == props.selected))

    watch(current, (newVal) => {
      emit('selectedLocale', newVal!.code)
    })
    const isOpen = ref(false)

    return {
      current,
      languages,
      isOpen,
    }
  },
})
</script>