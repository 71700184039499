<template>
  <div class="input-component">
    <div :class="error == null ? 'input-wrapper' : 'input-wrapper-error'">
      <GlowingCardComponent :is-glowing="isFocused">
        <div class="input-holder">
          <input v-model="input" @focus="onFocus" @blur="onBlur" id="primary-input" :placeholder="$t(placeholder)"
                 :type="inputType">
          <img :src="passwordVisible ? require('@/assets/icons/eye_cross.svg') : require('@/assets/icons/eye.svg')"
               v-if="type == 'password'" @click="togglePasswordVisibility">
        </div>
      </GlowingCardComponent>
    </div>
    <p v-if="error != null" class="input-error">{{ error }}</p>
  </div>
</template>

<style scoped>

.input-wrapper {
  border-radius: 12px;
  border: 1.5px solid rgba(0, 7, 20, 0.2);
}

.input-wrapper-error {
  border-radius: 12px;
  border: 1.5px solid rgb(255, 60, 25);
}

.input-error {
  margin-top: 8px;
  color: var(--error-5);
}

input {
  border: none;
  color: var(--black5);
  font-size: 16px;
  width: 100%;
  background-color: transparent;
}

input::placeholder {
  color: var(--techno-blue-9);
  opacity: 0.2;
}

input:-ms-input-placeholder {
  color: var(--techno-blue-9);
  opacity: 0.2;
}

input::-ms-input-placeholder {
  color: var(--techno-blue-9);
  opacity: 0.2;
}

.input-holder {
  background-color: transparent;
  width: 100%;
  padding: 16px;
  border-radius: var(--standard-round);
  display: flex;
  align-content: center;
}

input:focus {
  outline: none;
}

</style>

<script lang="ts">

import {computed, defineComponent, PropType, ref, watch} from "vue";
import GlowingCardComponent from "@/components/GlowingCardComponent.vue";

export default defineComponent({
  name: 'InputComponent',
  components: {GlowingCardComponent},
  emits: ['input'],
  props: {
    onInputFocus: {
      type: Function as PropType<() => void>,
      required: false,
      default: () => {
        console.log()
      }
    },
    placeholder: {
      required: true,
      type: String
    },
    required: Boolean,
    type: String,
    enabled: Boolean,
    error: String
  },
  setup(props, {emit}) {

    const isFocused = ref(false)
    const passwordVisible = ref(false);
    const input = ref('')
    const error = ref<string | null>(null);

    watch(input, (newVal) => {
      emit('input', newVal)
    })

    watch(() => props.error, (newValue, _) => {
      if (newValue != null) error.value = newValue
    });

    const inputType = computed(() => {
      if (props.type === 'password') {
        return passwordVisible.value ? 'text' : 'password';
      } else {
        return props.type;
      }
    });

    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const onFocus = () => {
      isFocused.value = true
      props.onInputFocus()
    };

    const onBlur = () => {
      isFocused.value = false
    };

    return {
      inputType, togglePasswordVisibility, passwordVisible, input, isFocused, onFocus, onBlur
    }

  }
})

</script>