import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2f19ed8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "loader"
}
const _hoisted_3 = {
  key: 1,
  class: "button-label-icon"
}
const _hoisted_4 = { class: "button-label" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: !_ctx.enabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.isLoading && _ctx.click())),
    class: _normalizeClass(_ctx.$props.buttonStyle)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.styleForContent)
    }, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2))
        : _createCommentVNode("", true),
      (!_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.buttonName)), 1),
            (_ctx.icon != null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: _normalizeClass(_ctx.styleForIcon),
                  src: require(`@/assets/icons/${_ctx.icon}`)
                }, null, 10, _hoisted_5))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 10, _hoisted_1))
}