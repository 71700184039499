import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20009c77"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selected" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "selected_language" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 0,
  class: "options"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "option-language" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "selector",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
    ref: "dropdownParent"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: require(`@/assets/icons/${_ctx.current.icon}`),
        alt: _ctx.current.title
      }, null, 8, _hoisted_2),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.current.title), 1),
      _createElementVNode("img", {
        src: _ctx.isOpen ? require('@/assets/icons/chevron_up.svg') : require('@/assets/icons/chevron_down.svg'),
        alt: _ctx.isOpen ? 'Up' : 'Down'
      }, null, 8, _hoisted_4)
    ]),
    _createVNode(_Transition, { name: "dropdown" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (lan) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "option",
                  key: lan.code,
                  onClick: ($event: any) => (_ctx.selectLanguage(lan))
                }, [
                  _createElementVNode("img", {
                    src: require(`@/assets/icons/${lan.icon}`),
                    alt: lan.title
                  }, null, 8, _hoisted_7),
                  _createElementVNode("p", _hoisted_8, _toDisplayString(lan.title), 1)
                ], 8, _hoisted_6))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 512))
}