import {AxiosResponse} from "axios";
import {LoginModelDto} from "@/models/LoginModelDto";
import {TokenModelDto} from "@/models/TokenModelDto";
import apiService from "@/services/apiService";
import {GoogleLoginDto} from "@/models/GoogleLoginDto";
import {SignUpDto} from "@/models/SignUpDto";
import {SuccessDto} from "@/models/SuccessDto";

export const getTokens = async (model: LoginModelDto): Promise<TokenModelDto> => {
    const response: AxiosResponse<TokenModelDto> = await apiService.post('/trusted/auth/sign-in', model);
    return response.data;
}

export const signUp = async (model: SignUpDto) => {
    const response: AxiosResponse<SuccessDto> = await apiService.post('/trusted/auth/sign-up', model);
    return response.data;
}

export const authorizeByGoogle = async (model: GoogleLoginDto) => {
    const response: AxiosResponse<TokenModelDto> = await apiService.post('/trusted/auth/sign-in-google', model);
    return response.data;
}