export class CarPostModel {
    id: number
    isPromoted: boolean;
    isVerified: boolean;
    isEco: boolean;
    location: string
    postDate: string;
    viewCount: string;
    price: string;
    currency: string;
    brandModel: string;
    mileage: string;
    mileageUnit: string;
    year: string
    banners: string[]

    constructor(
        id: number,
        isPromoted: boolean,
        isVerified: boolean,
        isEco: boolean,
        location: string,
        postDate: string,
        viewCount: string,
        price: string,
        currency: string,
        brandModel: string,
        mileage: string,
        mileageUnit: string,
        year: string,
        banners: string[]
    ) {
        this.id = id;
        this.isPromoted = isPromoted;
        this.isVerified = isVerified;
        this.isEco = isEco;
        this.location = location;
        this.postDate = postDate;
        this.viewCount = viewCount;
        this.price = price;
        this.currency = currency;
        this.brandModel = brandModel;
        this.mileage = mileage;
        this.mileageUnit = mileageUnit
        this.year = year;
        this.banners = banners;
    }

}