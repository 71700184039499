<template>
  <div v-if="isVisible" class="modal-backdrop" @click="onBackdropClick">
    <div class="modal-content" @click.stop>
      <img class="icon"
           :src="type == 'success' ? require('@/assets/icons/success.svg') : require('@/assets/icons/error.svg')">
      <h2 class="title">{{ type != 'success' ? $t('something_is_wrong') : $t('u_re_setup') }}</h2>
      <p class="subtitle" v-if="message != '' && message != null">{{ message }}</p>
      <ButtonComponent v-if="primaryButtonTitle != ''" class="closeBtn" @click="action1"
                       :button-name="primaryButtonTitle" button-style="primary"/>
      <ButtonComponent v-if="secondaryButtonTitle != ''" class="closeBtn" @click="action2"
                       :button-name="secondaryButtonTitle" button-style="secondary"/>
    </div>
  </div>
</template>

<style scoped>
.modal-backdrop > .modal-content {
  width: 413px;
  background: white;
  padding: 32px;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
}

.subtitle {
  color: var(--mono-5);
  margin-top: 12px;
  margin-bottom: 20px;
}

.title {
  font-size: 32px;
  margin-top: 16px;
}

.closeBtn {
  width: 100%;
  margin-top: 12px;
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 7, 20, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>


<script lang="ts">
import {defineComponent, PropType, ref, watch} from 'vue';
import ButtonComponent from "@/components/ButtonComponent.vue";

export default defineComponent({
  components: {ButtonComponent},
  props: {
    modelValue: Boolean,
    type: String,
    message: String,
    primaryButtonTitle: {
      type: String,
      required: false,
      default: ''
    },
    primaryButtonAction: {
      type: Function as PropType<() => void>,
      required: false,
      default: () => {
        console.log()
      }
    },
    secondaryButtonTitle: {
      type: String,
      required: false,
      default: ''
    },
    secondaryButtonAction: {
      type: Function as PropType<() => void>,
      required: false,
      default: () => {
        console.log()
      }
    },
    backdropClickAction: {
      type: Function as PropType<() => void>,
      required: false,
      default: () => {
        console.log()
      }
    }
  },
  setup(props, {emit}) {
    const isVisible = ref(props.modelValue);

    watch(() => props.modelValue, (newVal) => {
      isVisible.value = newVal;
    });

    const action1 = () => {
      closeModal()
      props.primaryButtonAction()
    }

    const action2 = () => {
      closeModal()
      props.primaryButtonAction()
    }

    const onBackdropClick = () => {
      closeModal()
      props.backdropClickAction()
    }

    const closeModal = () => {
      isVisible.value = false;
      emit('update:modelValue', false);
    }

    return {
      isVisible,
      closeModal,
      action1,
      action2,
      onBackdropClick
    };
  }
});
</script>