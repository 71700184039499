<template>
  <NavBarComponent/>
  <router-view/>
  <FooterComponent/>
</template>
<style>
#app {
  background-color: #fff;
  padding-top: 48px;
}
</style>

<script lang="ts">
import {defineComponent} from 'vue';
import NavBarComponent from "@/components/NavBarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default defineComponent({
  name: 'App',
  components: {FooterComponent, NavBarComponent},
});
</script>